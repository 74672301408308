import {Nullable} from '@fl/cmsch-fe-library';
import {isEmpty, replace} from 'lodash/fp';
import React, {Fragment, FC, memo, useCallback} from 'react';
import {Opt} from 'ts-opt';

import {OrderSearch} from 'api/gen/OrderSearch';
import {Panel} from 'layout';
import {validate} from 'orders/components/FindOrderForm/validation';
import {useOurTranslation} from 'translations';
import {useForm} from 'utils/forms';

import {FindOrderFormValues} from './find-order-form-values';

const initialValues: FindOrderFormValues = {
    earTag: null,
    barcode: null,
};

interface Props {
    foundOrders: Nullable<Array<OrderSearch>>;
    onSubmitBarcode(barcode: string): void;
    onSubmitEarTag(earTag: string): void;
}

const FindOrderFormBase: FC<Props> = props => {
    const {
        foundOrders,
        onSubmitBarcode,
        onSubmitEarTag,
    } = props;

    const {t, tCommon} = useOurTranslation('orders/FindOrderForm');

    const {Form, Fields, blur} = useForm({
        form: 'findOrder',
        initialValues,
        destroyOnUnmount: true,
        validate,
    });

    const handleSubmitBarcode = useCallback((value: Opt<string>) => {
        value.map(replace(/\s+/g, '')).onSome(onSubmitBarcode).orCrash('barcode is not available');
    }, [onSubmitBarcode]);

    const handleSubmitEarTag = useCallback((value: Opt<string>) => {
        value.onSome(onSubmitEarTag).orCrash('earTag is not available');
    }, [onSubmitEarTag]);

    const handleOnBlur = useCallback((value: Opt<string>) => {
        value.map(replace(/\s+/g, '')).onSome(y => {
            blur('barcode', y);
        });
    }, [blur]);

    return (
        <Panel>
            <Form>
                <fieldset>
                    <legend>{t('findOrder')}</legend>

                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <Fields.Input
                                name="barcode"
                                label={t('barcode')}
                                type="text"
                                onAction={handleSubmitBarcode}
                                actionOnPaste
                                actionOnEnter
                                autoFocus
                                onFieldBlur={handleOnBlur}
                            />
                        </div>
                    </div>

                    {foundOrders !== null && isEmpty(foundOrders) && (
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <Fields.Input
                                    name="earTag"
                                    label={tCommon('earTag')}
                                    placeholder={tCommon('earTagPlaceholder')}
                                    type="text"
                                    onAction={handleSubmitEarTag}
                                    actionOnPaste
                                    actionOnEnter
                                    autoFocus
                                />
                            </div>
                        </div>
                    )}

                    {foundOrders !== null && foundOrders.length > 1 && (
                        <Fragment>
                            <div className="row">
                                <div className="col-12">{t('foundMultipleOrders')}:</div>
                            </div>
                            <div className="row">
                                {foundOrders.map(order => (
                                    <div
                                        className="col-12"
                                        key={order.id}
                                    >
                                        <a href={`/orders/${order.id}#receive`}>
                                            {order.earTag}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    )}
                </fieldset>
            </Form>
        </Panel>
    );
};

export const FindOrderForm = memo(FindOrderFormBase);
